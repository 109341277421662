import React, { Fragment } from 'react';
import { compose } from 'recompose';
import SignOutButton from '../components/SignOut';
import Layout from '../components/layout';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../components/Session';
import PasswordForgetForm from '../components/PasswordForget';
import PasswordChangeForm from '../components/PasswordChange';
import LoginManagement from '../components/LoginManagement';

const AccountPageBase = () => (
  <Fragment>
    <AuthUserContext.Consumer>

      {authUser => (
      <div className="page-content header-clear-medium">  
        {/* <div className="card card-style">
            <div className="content mb-0">
                <h2>Basic Information</h2>
                <p className="mb-5">
                    This contains basic profile fields, easily editable, set to disable or pre-populate with useful information.
                </p>
                <div className="input-style input-style-2 has-icon input-required">
                    <i className="input-icon fa fa-user"></i>
                    <span className="color-highlight input-style-1-active">Name</span>
                    <em>(required)</em>
                    <input type="name" className="form-control" value="Jackson Doe"/>
                </div> 
                <div className="input-style input-style-2 has-icon input-required mt-4">
                    <i className="input-icon fa fa-at"></i>
                    <span className="color-highlight input-style-1-active">Email Address</span>
                    <em>(required)</em>
                    <input type="email" className="form-control" value="jack.doe@domain.com"/>
                </div> 
                <div className="input-style input-style-2 has-icon input-required mt-4">
                    <i className="input-icon fa fa-map-marker"></i>
                    <span className="color-highlight input-style-1-active">Location</span>
                    <em>(required)</em>
                    <input type="text" className="form-control" value="Melbourne, Australia"/>
                </div> 
                <div className="input-style input-style-2 has-icon input-required mt-4">
                    <i className="input-icon fa fa-phone"></i>
                    <span className="color-highlight input-style-1-active">Phone Number</span>
                    <em>(required)</em>
                    <input type="phone" className="form-control" value="+1 234 5678 9134"/>
                </div> 
                <a href="#" className="btn btn-full bg-green1-dark btn-m text-uppercase rounded-sm shadow-l mb-3 mt-4 font-900">Save Basic Information</a>
            </div>
        </div>         */}

        <div className="card card-style">
            <div className="content mb-2">
              <h1>{authUser.email}</h1><SignOutButton />
            </div>
        </div>

        <LoginManagement authUser={authUser} />

        {/* <div className="card card-style">
          <div className="content mb-2">
                <PasswordForgetForm />
          </div>
        </div> */}

      </div>
      )}

    </AuthUserContext.Consumer>

  </Fragment>
);

const condition = authUser => !!authUser;

const AccountPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(AccountPageBase);

export default () => (
  <Layout>
    <AccountPage />
  </Layout>
);
